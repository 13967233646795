const FooterComponent = () => {

    return (
        <>
            <a href="#0" className="cd-top">Top</a>

            <footer>
                <div className="container">
                    <ul className="footer-nav">
                        <li className="footer-privacy"><a href="/privacy-policy">Privacy Policy</a></li>
                        <li className="footer-terms"><a href="/terms-of-use">Terms of use</a></li>
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default FooterComponent;