import MetaTags from "react-meta-tags";

const TermsOfUsePage = () => {
    return (
        <>
            <MetaTags>
                <title>Terms of Use - EcamSync Cloud Storage</title>
            </MetaTags>
            <section className="terms_of_use mt-5">
                <div className="container">
                    <h1 id="terms_info_title">Terms of Use</h1>
                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-21 col-xs-12">
                            <ul className="contacts_section_info">
                                <li>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the
                                    www.ecams.io website, Ecams mobile applications, and Ecams software operated by
                                    Ecams.
                                </li>
                                <li>Your access to and use of the Service is conditioned upon your acceptance of and
                                    compliance with these Terms. These Terms apply to all visitors, users, and others
                                    who wish to access or use the Service.
                                </li>
                                <li>By accessing or using the Service, you agree to be bound by these Terms. If you
                                    disagree with any part of the terms, then you do not have permission to access the
                                    Service.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_collection"
                             className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Subject</span>
                            <ul className="contacts_section_info">
                                <li className="">Ecams. offers you Ecams Storage Service, which allows you to view,
                                    record and process video and audio data from any private or public premises using
                                    Ecams-branded equipment. This Service provides you with the ability of online
                                    viewing/listening, recording of video and audio data directly by using Ecams video
                                    cameras, video recorders or other Ecams devices. This Service provides you with the
                                    remote access to equipment and recorded information, the ability to download this
                                    information to computer or mobile devices, as well as motion detection and other
                                    related services, which can be found in more details at <a
                                        href="http://ecams.io/">www.ecams.io</a></li>
                                <li>Currently existing Service, as well as any of its development, expansion or
                                    reduction shall be the subject of these Terms.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_cookies" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">General Provisions</span>
                            <ul className="contacts_section_info">
                                <li className="">You agree to use the Service solely for the purposes permitted by the
                                    Service and any applicable laws, provisions, generally accepted practices or
                                    regulations in the relevant jurisdiction.
                                </li>
                                <li>You agree not to engage in any activities that interfere with the provision of the
                                    Service (or operation of the relevant servers and networks connected with the
                                    Service), or disrupts its provision.
                                </li>
                                <li>The software used within the Service may automatically download and install on your
                                    devices the updates provided by Ecams. which may be provided as bug fixes, changed
                                    functions, updated software modules or completely new versions. You agree to receive
                                    and allow Service to provide and install such updates on your devices as part of
                                    using the Service.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_distr" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Purchases</span>
                            <ul className="contacts_section_info">
                                <li className="">If you wish to purchase any product or service made available through
                                    the Service ("Purchase"), you may be asked to supply certain information relevant to
                                    your Purchase including, without limitation, your credit card number, the expiration
                                    date of your credit card, your billing address, and your shipping information.
                                </li>
                                <li>You represent and warrant that: (i) you have the legal right to use any credit
                                    card(s) or other payment method(s) in connection with any Purchase; and that (ii)
                                    the information you supply to us is true, correct and complete.
                                </li>
                                <li>The service may employ the use of third party services for the purpose of
                                    facilitating payment and the completion of Purchases. By submitting your
                                    information, you grant us the right to provide the information to these third
                                    parties subject to our Privacy Policy at <a
                                        href="https://ecams.io/privacy-policy/">www.ecams.io/privacy-policy/</a></li>
                                <li>We reserve the right to refuse or cancel your order at any time for reasons
                                    including but not limited to: product or service availability, errors in the
                                    description or price of the product or service, error in your order or other
                                    reasons.
                                </li>
                                <li>We reserve the right to refuse or cancel your order if fraud or an unauthorized or
                                    illegal transaction is suspected. In case of any questions or concerns, please
                                    contact us via email <a href="mailto:support@ecams.io">support@ecams.io</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_manage" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Availability, Errors and Inaccuracies</span>
                            <ul className="contacts_section_info">
                                <li className="">We are constantly updating product and service offerings on the
                                    Service. We may experience delays in updating information on the Service and in our
                                    advertising on other web sites. The information found on the Service may contain
                                    errors or inaccuracies and may not be complete or current. Products or services may
                                    be mispriced, described inaccurately, or unavailable on the Service and we cannot
                                    guarantee the accuracy or completeness of any information found on the Service.
                                </li>
                                <li>We therefore reserve the right to change or update information and to correct
                                    errors, inaccuracies, or omissions at any time without prior notice.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_security" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Subscriptions</span>
                            <ul className="contacts_section_info">
                                <li className="">Some parts of the Service are billed on a subscription basis
                                    ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis
                                    ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis,
                                    depending on the type of subscription plan you select when purchasing a
                                    Subscription.
                                </li>
                                <li>At the end of each Billing Cycle, your Subscription may automatically renew under
                                    the exact same conditions unless you cancel it or Ecams. cancels it. You may cancel
                                    your Subscription renewal through your online account management page.
                                </li>
                                <li>A valid payment method, including credit card or PayPal, is required to process the
                                    payment for your balance to be able to use your Subscription. You shall provide
                                    Ecams. with accurate and complete billing information including full name, address,
                                    state, zip code, telephone number, and a valid payment method information. By
                                    submitting such payment information, you automatically authorize Ecams. to charge
                                    confirmed amount from your credit card or PayPal through our payment gateway
                                    provider.
                                </li>
                                <li>Should automatic billing fail to occur for any reason, Ecams. will issue an
                                    electronic invoice indicating that you must proceed manually, within a certain
                                    deadline date, with the full payment corresponding to the billing amount as
                                    indicated on the invoice.
                                </li>
                                <li>You will be solely responsible for paying all local charges together with all
                                    applicable taxes associated with payments or balance top-ups in our Service. Any
                                    legal requirement to pay such charges and taxes, as well as other compensation for
                                    use of the Service, is a part of this Agreement.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_links" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Free Trial</span>
                            <ul className="contacts_section_info">
                                <li className="">Ecams. may, at its sole discretion, offer a Subscription with a free
                                    trial for a limited period of time ("Free Trial").
                                </li>
                                <li>You may be required to enter your billing information in order to sign up for the
                                    Free Trial.
                                </li>
                                <li>Even if you do enter your billing information or top up your balance when signing up
                                    for the Free Trial, you will not be charged by Ecams. until the Free Trial has
                                    expired. To apply for the paid services or Subscriptions you have to select and
                                    switch on these Subscriptions after the Free Trial period will expire.
                                </li>
                                <li>At any time and without notice, Ecams. reserves the right to (i) modify the terms
                                    and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_secur" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Compliance With Laws</span>
                            <ul className="contacts_section_info">
                                <li className="">We will disclose your Personal Information where required to do so by
                                    law or subpoena or if we believe that such action is necessary to comply with the
                                    law and the reasonable requests of law enforcement or to protect the security or
                                    integrity of our Service.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_children" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Fee Changes</span>
                            <ul className="contacts_section_info">
                                <li className="">Ecams., in its sole discretion and at any time, may modify the
                                    Subscription fees for the Subscriptions. Any Subscription fee change will become
                                    effective at the end of the then-current Billing Cycle.
                                </li>
                                <li>Ecams. will provide you with a reasonable prior notice of any change in Subscription
                                    fees to give you an opportunity to terminate your Subscription before such change
                                    becomes effective.
                                </li>
                                <li>Your continued use of the Service after the Subscription fee change comes into
                                    effect constitutes your agreement to pay the modified Subscription fee amount.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_error" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Refunds</span>
                            <ul className="contacts_section_info">
                                <li className="">Certain refund requests for Subscriptions may be considered by Ecams.
                                    on a case-by-case basis and granted in sole discretion of Ecams. In case of any
                                    questions or concerns, please contact us via email <a
                                        href="mailto:support@ecams.io">support@ecams.io</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_legal" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Accounts</span>
                            <ul className="contacts_section_info">
                                <li className="">When you create an account with us, you guarantee that you are above
                                    the age of 18, and that the information you provide us is accurate, complete, and
                                    current at all times. Inaccurate, incomplete, or obsolete information may result in
                                    the immediate termination of your account on the Service.
                                </li>
                                <li>You are responsible for maintaining the confidentiality of your account and
                                    password, including but not limited to the restriction of access to your computer
                                    and/or account. You agree to accept responsibility for any and all activities or
                                    actions that occur under your account and/or password, whether your password is with
                                    our Service or a third-party service. You must notify us immediately upon becoming
                                    aware of any breach of security or unauthorized use of your account.
                                </li>
                                <li>You may not use as a username the name of another person or entity or that is not
                                    lawfully available for use, a name or trademark that is subject to any rights of
                                    another person or entity other than you, without appropriate authorization. You may
                                    not use as a username any name that is offensive, vulgar or obscene.
                                </li>
                                <li>We reserve the right to refuse service, terminate accounts, remove or edit content,
                                    or cancel orders in case of any fraud, abuse, illegal or prohibited content
                                    broadcasting, risk of lost or damage, violation of any part of these Terms or
                                    Privacy Policy and other undesirable acts in our sole discretion. In case of any
                                    questions or concerns, please contact us via email <a
                                        href="mailto:support@ecams.io">support@ecams.io</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Copyright Policy</span>
                            <ul className="contacts_section_info">
                                <li className="">We respect the intellectual property rights of others. It is our policy
                                    to respond to any claim that Content posted on the Service infringes on the
                                    copyright or other intellectual property rights ("Infringement") of any person or
                                    entity.
                                </li>
                                <li>If you are a copyright owner, or authorized on behalf of one, and you believe that
                                    the copyrighted work has been copied in a way that constitutes copyright
                                    infringement, please submit your claim via email to support@ecams.io, with the
                                    subject line: "Copyright Infringement" and include in your claim a detailed
                                    description of the alleged Infringement as detailed below, under "DMCA Notice and
                                    Procedure for Copyright Infringement Claims".
                                </li>
                                <li>You may be held accountable for damages (including costs and attorneys' fees) for
                                    misrepresentation or bad-faith claims on the infringement of any Content found on
                                    and/or through the Service on your copyright.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">DMCA Notice and Procedure for Copyright Infringement Claims</span>
                            <ul className="contacts_section_info">
                                <li className="">You may submit a notification pursuant to the Digital Millennium
                                    Copyright Act (DMCA) by providing our Copyright Agent with the following information
                                    in writing (see 17 U.S.C 512(c)(3) for further detail):
                                </li>
                                <ul>
                                    <li>- an electronic or physical signature of the person authorized to act on behalf
                                        of the owner of the copyright's interest;
                                    </li>
                                    <li>- a description of the copyrighted work that you claim has been infringed,
                                        including the URL (i.e., web page address) of the location where the copyrighted
                                        work exists or a copy of the copyrighted work;
                                    </li>
                                    <li>- identification of the URL or other specific location on the Service where the
                                        material that you claim is infringing is located;
                                    </li>
                                    <li>- your address, telephone number, and email address;</li>
                                    <li>- a statement by you that you have a good faith belief that the disputed use is
                                        not authorized by the copyright owner, its agent, or the law;
                                    </li>
                                    <li>- a statement by you, made under penalty of perjury, that the above information
                                        in your notice is accurate and that you are the copyright owner or authorized to
                                        act on the copyright owner's behalf.
                                    </li>
                                </ul>
                                <li>You can contact our Copyright Agent via email at <a
                                    href="mailto:support@ecams.io">support@ecams.io</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Intellectual Property</span>
                            <ul className="contacts_section_info">
                                <li className="">The Service and its original content, features and functionality are
                                    and will remain the exclusive property of Ecams. and its licensors. The Service is
                                    protected by copyright, trademark, and other laws of both the Republic of Tanzania
                                    and foreign countries. Our trademarks and trade dress may not be used in connection
                                    with any product or service without the prior written consent of Ecams.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Links To Other Web Sites</span>
                            <ul className="contacts_section_info">
                                <li className="">Our Service may contain links to third party web sites or services that
                                    are not owned or controlled by Ecams.
                                </li>
                                <li>Ecams. has no control over, and assumes no responsibility for the content, privacy
                                    policies, or practices of any third party web sites or services. We do not warrant
                                    the offerings of any of these entities/individuals or their websites.
                                </li>
                                <li>You acknowledge and agree that Ecams. shall not be responsible or liable, directly
                                    or indirectly, for any damage or loss caused or alleged to be caused by or in
                                    connection with use of or reliance on any such content, goods or services available
                                    on or through any such third party web sites or services.
                                </li>
                                <li>We strongly advise you to read the terms and conditions and privacy policies of any
                                    third party web sites or services that you visit.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Termination</span>
                            <ul className="contacts_section_info">
                                <li className="">We may terminate or suspend your account and access to the Service
                                    immediately, without prior notice or liability, under our sole discretion, for any
                                    reason whatsoever and without limitation, including but not limited to a breach of
                                    the Terms.
                                </li>
                                <li>If you wish to terminate your account, you may simply discontinue using the Service.
                                    If you wish to delete your account, please contact us via email <a
                                        href="mailto:support@ecams.io">support@ecams.io</a>.
                                </li>
                                <li>All provisions of the Terms which by their nature should survive termination shall
                                    survive termination, including, without limitation, ownership provisions, warranty
                                    disclaimers, indemnity and limitations of liability.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Indemnification</span>
                            <ul className="contacts_section_info">
                                <li className="">You agree to defend, indemnify and hold harmless Ecams. and its
                                    licensee and licensors, and their employees, contractors, agents, officers and
                                    directors, from and against any and all claims, damages, obligations, losses,
                                    liabilities, costs or debt, and expenses (including but not limited to attorney's
                                    fees), resulting from or arising out of a) your use and access of the Service, by
                                    you or any person using your account and password, or b) a breach of these Terms.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Limitation Of Liability</span>
                            <ul className="contacts_section_info">
                                <li className="">In no event shall Ecams., nor its directors, employees, partners,
                                    agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
                                    consequential or punitive damages, including without limitation, loss of profits,
                                    data, use, goodwill, or other intangible losses, resulting from (i) your access to
                                    or use of or inability to access or use the Service; (ii) any conduct or content of
                                    any third party on the Service; (iii) any content obtained from the Service; and
                                    (iv) unauthorized access, use or alteration of your transmissions or content,
                                    whether based on warranty, contract, tort (including negligence) or any other legal
                                    theory, whether or not we have been informed of the possibility of such damage, and
                                    even if a remedy set forth herein is found to have failed of its essential purpose.
                                </li>
                                <li>Using our Service, you confirm that the liability for all information (such as audio
                                    files, video files and other data) to which you have access as to part of the
                                    Service or through the use of the Service shall be assumed by the person who
                                    provided or created the information.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Disclaimer</span>
                            <ul className="contacts_section_info">
                                <li className="">Your use of the Service is at your sole risk. The Service is provided
                                    on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties
                                    of any kind, whether express or implied, including, but not limited to, implied
                                    warranties of merchantability, fitness for a particular purpose, non-infringement or
                                    course of performance.
                                </li>
                                <li>Ecams. its subsidiaries, affiliates, and its licensors do not warrant that a) the
                                    Service will function uninterrupted, secure or available at any particular time or
                                    location; b) any errors or defects will be corrected; or c) the results of using the
                                    Service will meet your requirements.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Exclusions</span>
                            <ul className="contacts_section_info">
                                <li className="">Some jurisdictions do not allow the exclusion of certain warranties or
                                    the exclusion or limitation of liability for consequential or incidental damages, so
                                    the limitations above may not apply to you.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Governing Law</span>
                            <ul className="contacts_section_info">
                                <li className="">These Terms shall be governed and construed in accordance with the laws
                                    of Republic of Tanzania, without regard to its conflict of law provisions.
                                </li>
                                <li>Our failure to enforce any right or provision of these Terms will not be considered
                                    a waiver of those rights. If any provision of these Terms is held to be invalid or
                                    unenforceable by a court, the remaining provisions of these Terms will remain in
                                    effect. These Terms constitute the entire agreement between us regarding our
                                    Service, and supersede and replace any prior agreements we might have had between us
                                    regarding the Service.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Changes</span>
                            <ul className="contacts_section_info">
                                <li className="">We reserve the right, at our sole discretion, to modify or replace
                                    these Terms at any time. If a revision is material, we will provide at least 30 days
                                    notice prior to any new terms taking effect. What constitutes a material change will
                                    be determined at our sole discretion.
                                </li>
                                <li>By continuing to access or use our Service after any revisions become effective, you
                                    agree to be bound by the revised terms. If you do not agree to the new terms, you
                                    are no longer authorized to use the Service.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Contact Us</span>
                            <ul className="contacts_section_info">
                                <li className="contact_padding">For any inquiries regarding this Privacy Policy, please
                                    feel free to reach out to us:
                                </li>
                                <li>Phone: 255-614366359</li>
                                <li>Email: <a href="mailto:support@ecams.io">support@ecams.io</a></li>
                                <li>Street Address: House No 628, Biafra Street</li>
                                <li>Dar Es Salaam, 11304</li>
                                <li>Tanzania, United Republic of</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsOfUsePage;