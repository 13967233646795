import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from 'yup';
import {useState} from "react";

const LoginPage = () => {
    const [error, serError] = useState();

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Field is required'),
        password: Yup.string().required('Field is required'),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        if ((values?.email === 'demo@ecams.io' && values?.password === 'KE7ju26f5A') ||
            (values?.email === localStorage.getItem("userEmail") && values?.password === localStorage.getItem("password"))) {
            localStorage.setItem("userEmail", values?.email);
            window.location.href = '/profile';
        } else {
            serError("Invalid credentials")
        }

        setSubmitting(false)
    };

    return (
        <>
            <MetaTags>
                <title>Login</title>
            </MetaTags>
            <div className="wview">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-12 col-lg-6">
                            <div className="card w-100">
                                <div className="p-4">
                                    <Formik
                                        initialValues={{email: '', password: ''}}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <h2 className="mb-3 fw-bold">
                                                    Login
                                                </h2>
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        label="Email"
                                                        error={errors.email && touched.email}
                                                        helperText={errors.email && touched.email && errors.email}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        label="Password"
                                                        error={errors.password && touched.password}
                                                        helperText={errors.password && touched.password && errors.password}
                                                    />
                                                </div>

                                                {error && <span className="text-danger">
                                                    {error}
                                                </span>}

                                                <Button
                                                    className="btn-yellow send w-100"
                                                    fullWidth
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "..." : "Login"}
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;