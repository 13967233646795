import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from 'yup';
import {useState} from "react";

const ContactSchema = Yup.object().shape({
    ip_address: Yup.string().required('Field is required'),
    port: Yup.string().required('Field is required'),
    user: Yup.string().required('Field is required'),
    password: Yup.string().required('Field is required'),
});

const ProfilePage = () => {
    const [error, setError] = useState();
    const [save, setSave] = useState(false);

    const [devices, setDevices] = useState(
        JSON.parse(localStorage.getItem("devices")) || []
    );

    const userEmail = localStorage.getItem("userEmail") || {}
    const userPassword = localStorage.getItem("userPassword") || {}

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        const {index, ...deviceData} = values;
        const updatedDevices = [...devices];
        updatedDevices[index] = deviceData;

        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
        setSave(true);
        setSave(true);
        setSubmitting(false);
    };

    const handleDeleteDevice = (index) => {
        const updatedDevices = devices.filter((_, i) => i !== index);
        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
    };

    const handleAddEmptyDevice = () => {
        if (devices.length < 4) {
            const emptyDevice = {
                ip_address: "",
                port: "",
                user: "",
                password: "",
            };
            setDevices([...devices, emptyDevice]);
        } else {
            setError("You can only add up to 4 devices.");
        }
    };

    return (
        <>
            <MetaTags>
                <title>Profile</title>
            </MetaTags>
            <div className="wview">
                <div className="container">

                    <div className="row my-5">
                        <div className="col-12 col-lg-12">
                            <div className=" w-100">
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <h1 className="fw-bold">Profile</h1>
                                                    <div className="mb-3">
                                                        <TextField id="outlined-basic"
                                                                   label="Email"
                                                                   fullWidth
                                                                   variant="outlined"
                                                                   value={userEmail}
                                                                   disabled
                                                        />
                                                    </div>
                                                    <div className="mb-0">
                                                        <TextField id="outlined-basic"
                                                                   label="Password"
                                                                   fullWidth
                                                                   type="password"
                                                                   variant="outlined"
                                                                   value={userPassword}
                                                                   disabled
                                                        />
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="parent_btn blue_btn big_btn h_btn_60 right_btn mb-3"
                                         onClick={handleAddEmptyDevice}>
                                        <div className="child_btn partnership_btn">
                                            <a href="#">
                                                                <span>
                                                                    Add Device
                                                                </span>
                                                <i className="fa fa-angle-right fa-3x" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            {save &&
                                                <div
                                                    className="alert alert-success mb-3 mb-0 p-2 text-center"
                                                    role="alert">
                                                    Your data has been saved
                                                </div>}

                                            {error && <p className="text-danger mb-3">
                                                {error}
                                            </p>}
                                        </div>
                                    </div>

                                    <div className="row row-cols-4 g-3">
                                        {devices.map((device, index) => (
                                            <div className="col" key={index}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <Formik
                                                            initialValues={{
                                                                index: index,
                                                                ip_address: device?.ip_address || '',
                                                                port: device?.port || '',
                                                                user: device?.user || '',
                                                                password: device?.password || '',
                                                            }}
                                                            validationSchema={ContactSchema}
                                                            onSubmit={handleSubmit}
                                                        >
                                                            {({isSubmitting, errors, touched}) => (
                                                                <Form>
                                                                    <div
                                                                        className="row d-flex align-items-center g-3 mb-4">
                                                                        <div className="col-6">
                                                                            <h2 className="fw-bold mb-0">
                                                                                Device
                                                                            </h2>
                                                                        </div>
                                                                        <div className="col-6 text-end">
                                                                            <button className="btn btn-danger"
                                                                                    onClick={() => handleDeleteDevice(index)}>
                                                                                <i className="fa-solid fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                    <Field
                                                                        type="hidden"
                                                                        name="index"
                                                                        id="index"
                                                                        value={index}
                                                                    />
                                                                    <div className="mb-3">
                                                                        <Field
                                                                            as={TextField}
                                                                            fullWidth
                                                                            type="text"
                                                                            name="ip_address"
                                                                            id="ip_address"
                                                                            label="IP address"
                                                                            error={errors.ip_address && touched.ip_address}
                                                                            helperText={errors.ip_address && touched.ip_address && errors.ip_address}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Field
                                                                            as={TextField}
                                                                            fullWidth
                                                                            type="text"
                                                                            name="port"
                                                                            id="port"
                                                                            label="Port"
                                                                            error={errors.port && touched.port}
                                                                            helperText={errors.port && touched.port && errors.port}
                                                                        />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <Field
                                                                            as={TextField}
                                                                            fullWidth
                                                                            type="text"
                                                                            name="user"
                                                                            id="user"
                                                                            label="User"
                                                                            error={errors.user && touched.user}
                                                                            helperText={errors.user && touched.user && errors.user}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <Field
                                                                            as={TextField}
                                                                            fullWidth
                                                                            type="password"
                                                                            name="password"
                                                                            id="password"
                                                                            label="Password"
                                                                            error={errors.password && touched.password}
                                                                            helperText={errors.password && touched.password && errors.password}
                                                                        />
                                                                    </div>


                                                                    <Button
                                                                        className="btn-yellow send w-100"
                                                                        fullWidth
                                                                        variant="contained"
                                                                        type="submit"
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        {isSubmitting ? "..." : "Save"}
                                                                    </Button>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePage;