import MetaTags from "react-meta-tags";

const PrivacyPolicyPage = () => {
    return (
        <>
            <MetaTags>
                <title>Privacy policy - EcamSync Cloud Storage</title>
            </MetaTags>
            <section className="privacy_policy mt-5">
                <div className="container">
                    <h1 id="privacy_info_title">Privacy policy</h1>
                    <div className="row">

                        <div className="block-privacy-item col-lg-12 col-md-12 col-sm-21 col-xs-12">
                            <ul className="privacy_info">
                                <li>This page provides information about our policies regarding the collection, use, and
                                    disclosure of Personal Information when using our Service.
                                </li>
                                <li>We will only use or share your information as described in this Privacy Policy.</li>
                                <li>We use your Personal Information to provide and enhance the Service. By using the
                                    Service, you agree to the collection and use of information as outlined in this
                                    Privacy Policy. Unless defined differently in this Privacy Policy, terms have the
                                    same meanings as in our Terms of Use, accessible at <a
                                        href="http://ecams.io/terms-of-use/">www.ecams.io/terms-of-use/.</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">

                        <div id="info_collection"
                             className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Information Collection And Use</span>
                            <ul className="contacts_section_info">
                                <li className="">While using our Service, we may ask you to provide us with certain
                                    personally identifiable information that can be used to contact or identify you.
                                    Personally, identifiable information may include but is not limited to, your email
                                    address, name, phone number, other information ("Personal Information"). We collect
                                    this information for the purpose of providing the Service, identifying and
                                    communicating with you, responding to your requests/inquiries, servicing your
                                    purchase orders, subscriptions or payments and improving our services.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_cookies" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Log Data</span>
                            <ul className="contacts_section_info">
                                <li className="">We may also collect information that your browser sends whenever you
                                    visit our Service ("Log Data"). This Log Data may include information such as your
                                    computer's Internet Protocol ("IP") address, browser type, browser version, the
                                    pages of our Service that you visit, the time and date of your visit, the time spent
                                    on those pages and other statistics.
                                </li>
                                <li>In addition, we may use third-party services such as Google Analytics that collect,
                                    monitor and analyze this type of information in order to increase our Service's
                                    functionality. These third party service providers have their own privacy policies
                                    addressing how they use such information.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_distr" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Cookies</span>
                            <ul className="contacts_section_info">
                                <li className="">Cookies are files with a small amount of data, which may include an
                                    anonymous unique identifier. Cookies are sent to your browser from a website and
                                    transferred to your device. We use cookies to collect information in order to
                                    improve our services for you.
                                </li>
                                <li>You can instruct your browser to refuse all cookies or to indicate when a cookie is
                                    being sent. The Help feature on most browsers provides information on how to accept
                                    cookies, disable cookies or to notify you when receiving a new cookie.
                                </li>
                                <li>If you do not accept cookies, you may not be able to use some features of our
                                    Service and we recommend that you leave them turned on..
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_manage" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Do Not Track Disclosure</span>
                            <ul className="contacts_section_info">
                                <li className="">We support Do Not Track ("DNT"). Do Not Track is a preference you can
                                    set in your web browser to inform websites that you do not want to be tracked.
                                </li>
                                <li>You can enable or disable Do Not Track by visiting the Preferences or Settings page
                                    of your web browser.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_security" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Service Providers</span>
                            <ul className="contacts_section_info">
                                <li className="">We may employ third party companies and individuals to facilitate our
                                    Service, to provide the Service on our behalf, to perform Service-related services
                                    and/or to assist us in analyzing how our Service is used.
                                </li>
                                <li>These third parties have access to your Personal Information only to perform
                                    specific tasks on our behalf and are obligated not to disclose or use your
                                    information for any other purpose.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_links" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Communications</span>
                            <ul className="contacts_section_info">
                                <li className="">We may ask your permission to use your Personal Information to contact
                                    you with newsletters, marketing or promotional materials and other information that
                                    may be of interest to you. You may opt out of receiving any, or all, of these
                                    communications from us by following the unsubscribe link or instructions provided in
                                    any email we send or by contacting us.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_secur" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Compliance With Laws</span>
                            <ul className="contacts_section_info">
                                <li className="">We will disclose your Personal Information where required to do so by
                                    law or subpoena or if we believe that such action is necessary to comply with the
                                    law and the reasonable requests of law enforcement or to protect the security or
                                    integrity of our Service.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_children" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Security</span>
                            <ul className="contacts_section_info">
                                <li className="">The security of your Personal Information is important to us, and we
                                    strive to implement and maintain reasonable, commercially acceptable security
                                    procedures and practices appropriate to the nature of the information we store, in
                                    order to protect it from unauthorized access, destruction, use, modification, or
                                    disclosure.
                                </li>
                                <li>The connection between user and Service is protected with 128 bit TLS 1.2 encryption
                                    (HTTPS protocol). Data is stored using our cloud services and platforms.
                                </li>
                                <li>However, please be aware that no method of transmission over the internet, or method
                                    of electronic storage is 100% secure and we are unable to guarantee the absolute
                                    security of the Personal Information we have collected from you.
                                </li>
                                <li>If the information under our control is compromised as a result of a security
                                    breach, we will make all reasonable actions to investigate the situation and, where
                                    appropriate, to notify those persons whose data may have been affected, and to
                                    resolve this issue as much as possible in accordance with any applicable laws and
                                    regulations.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_error" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">International Transfer</span>
                            <ul className="contacts_section_info">
                                <li className="">Your information, including Personal Information, may be transferred to
                                    — and maintained on — computers located outside of your state, province, country or
                                    other governmental jurisdiction where the data protection laws may differ than those
                                    from your jurisdiction.
                                </li>
                                <li>If you are located outside the Republic of Tanzania and choose to provide
                                    information to us, please note that we transfer the information, including Personal
                                    Information, to the Republic of Tanzania and European Union and process it there.
                                </li>
                                <li>Your consent to this Privacy Policy followed by your submission of such information
                                    represents your agreement to that transfer.
                                </li>
                                <li>Transferring of the information to other countries is made according to suggestion,
                                    that this information will be protected accordingly to the protection level, which
                                    is required by laws or legal recommendations in the Republic of Tanzania and
                                    according to the EU new data privacy law, the General Data Protection Regulation
                                    (GDPR), which take effect from 25.05.2018.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_legal" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Links To Other Sites</span>
                            <ul className="contacts_section_info">
                                <li className="">Our Service may contain links to other sites that are not operated by
                                    us. If you click on a third party link, you will be directed to that third party's
                                    site. We strongly advise you to review the Privacy Policy of every site you visit.
                                </li>
                                <li>We have no control over and assume no responsibility for the content, privacy
                                    policies or practices of any third party sites or services.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Children's Privacy</span>
                            <ul className="contacts_section_info">
                                <li className="">Only persons age 18 or older have permission to access our Service. Our
                                    Service does not address anyone under the age of 16 ("Children").
                                </li>
                                <li>We do not knowingly collect personally identifiable information from children under
                                    the age of 16. If you are a parent or guardian and you learn that your Children have
                                    provided us with Personal Information, please contact us. If we become aware that we
                                    have collected Personal Information from children under the age of 16 without
                                    verification of parental consent, we take steps to remove that information from our
                                    servers.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Error-Free Operation</span>
                            <ul className="contacts_section_info">
                                <li className="">We do not guarantee error-free operation within the framework of this
                                    Privacy Policy. We may not always catch an unintended privacy issue, despite our
                                    efforts to do so. We welcome your comments that will help improve this Privacy
                                    Policy. We will use reasonable efforts to comply with this Privacy Policy and will
                                    take prompt corrective action when we learn of any failure to comply with it.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Legal Information</span>
                            <ul className="contacts_section_info">
                                <li className="">This Privacy Policy is subject to the terms and conditions of our Terms
                                    of Use Agreement. For a review go to <a
                                        href="http://ecams.io/terms-of-use/">www.ecams.io/terms-of-use</a>. It also
                                    contains conditions relating to limitations on damages, disclaimers of warranties
                                    and dispute resolution procedures. Our Terms of Use Agreement shall take precedence
                                    over any conflicting provisions of this Privacy Policy.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Changes To This Privacy Policy</span>
                            <ul className="contacts_section_info">
                                <li className="">This Privacy Policy is effective as of May 25, 2018 and will remain in
                                    effect except with respect to any changes in its provisions in the future, which
                                    will be in effect immediately after being posted on this page.
                                </li>
                                <li>We reserve the right to update or change our Privacy Policy at any time and you
                                    should check this Privacy Policy periodically. Your continued use of the Service
                                    after we post any modifications to the Privacy Policy on this page will constitute
                                    your acknowledgement of the modifications and your consent to abide and be bound by
                                    the modified Privacy Policy.
                                </li>
                                <li>If we make any material changes to this Privacy Policy, we will notify you either
                                    through the email address you have provided us or by placing a prominent notice on
                                    our website.
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="row">

                        <div id="info_contact" className="block-privacy-item col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <span className="">Contact Us</span>
                            <ul className="contacts_section_info">
                                <li className="contact_padding">For any inquiries regarding this Privacy Policy, please
                                    feel free to reach out to us:
                                </li>
                                <li>Phone: 255-614366359</li>
                                <li>Email: <a href="mailto:support@ecams.io">support@ecams.io</a></li>
                                <li>Street Address: House No 628, Biafra Street</li>
                                <li>Dar Es Salaam, 11304</li>
                                <li>Tanzania, United Republic of</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicyPage;