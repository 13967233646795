import MetaTags from 'react-meta-tags'

const ContactsPage = () => {
    return (
      <>
          <MetaTags>
              <title>Contact - EcamSync Cloud Storage</title>
          </MetaTags>

          <section className="contact_head box">
              <div className="container ">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="blue_hdr big_hdr hdr_26 bold_hdr contact_name">Contacts</div>
                          <div className="hr_contact_head"></div>
                          <ul className="contacts_section_info">
                              <li className="main_contact_name">House No 628, Biafra Street</li>
                              <li className="main_contact_address">Dar Es Salaam, 11304</li>
                              <li className="main_contact_address">Tanzania, United Republic of</li>
                              <li className="main_contact_email"><a href="mailto:sales@ecams.io">sales@ecams.io</a></li>
                              <li className="main_contact_tel">255-614366359</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>

          <section className="contact_tech">
              <div className="container">
                  <div className="row">
                      <div className="col-12 py-4">
                          <div className="blue_hdr hdr_26 bold_hdr contact_tech_name">Technical Support</div>
                          <ul className="contacts_section_info">
                              <li className="tech_contact_name"><a href="mailto:support@ecams.io">support@ecams.io</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
      </>
    )
}

export default ContactsPage;