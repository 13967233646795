import MetaTags from "react-meta-tags";

const HomePage = () => {
    return (
        <>
            <MetaTags>
                <title>EcamSync Cloud Storage</title>
            </MetaTags>
            <section className="features-list">
                <div className="container">
                    <div className="big_hdr white_hdr hdr_26 feature_list_name mb-5">
                        <p className="bold_hdr hdr_26">The unique complete solution for Cloud Storage</p>
                        <p>across all you surveillance manufacturer</p>
                    </div>

                    <div className="row row-cols-2 row-cols-lg-4 features_block">
                        <div className="col features mb-4">
                            <div>
                                <img src="assets/images/icon3-1.png"
                                     alt="Immediate alert notification via email or mobile"
                                     className="center-image mb-3"/>
                                <p className="solution_1 blue_hdr">
                                    Instant<br/>Alert Notifications<br/>via Email or Mobile
                                </p>
                            </div>
                        </div>
                        <div className="col features">
                            <div>
                                <img src="assets/images/icon3-2.png" alt="Your system is confidential and safe"
                                     className="center-image mb-3"/>
                                <p className="solution_2 blue_hdr">Your System<br/>is Secure<br/>and Confidential</p>
                            </div>
                        </div>
                        <div className="col features">
                            <div>
                                <img src="assets/images/icon3-3.png" alt="Cloud storage up to two years"
                                     className="mb-3 center-image"/>
                                <p className="solution_3 blue_hdr">Cloud Storage<br/>for up to<br/>Two Years</p>
                            </div>
                        </div>
                        <div className="col features">
                            <div>
                                <img src="assets/images/icon3-5.png" alt="Multiuser cloud video broadcasting"
                                     className="center-image mb-3"/>
                                <p className="solution_5 blue_hdr">Multiuser Cloud<br/>Video Broadcasting</p>
                            </div>
                        </div>
                    </div>

                    <div className="white_hdr feature_list_t">
                        If you're looking to install video surveillance at home or in your office, there's no need to
                        purchase
                        various equipment or delve into complex settings. All you need is to download our app and you
                        can
                        watch.
                    </div>
                </div>
            </section>

            <section className="cloudmaster">
                <div className="container">
                    <div className="">
                        <div className="row row-cols-1 row-cols-lg-3 justify-content-center">
                            <div className="col">
                                <div className="cloud-icon">
                                    <img src="assets/images/CloudMaster-1.png" alt="Wide compatibility!"/>
                                </div>
                                <div className="cloud-content">
                                    <span className="master_name_1">Wide Compatibility!</span>
                                    <p className="master_1">Connect to the Cloud service on a wide variety on your
                                        existing
                                        cameras.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="cloud-icon">
                                    <img src="assets/images/CloudMaster-2.png" alt="Absolutely free"/>
                                </div>
                                <div className="cloud-content">
                                    <span className="master_name_2">Absolutely Free</span>
                                    <p className="master_2">You can try all the features of the service for free.</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="cloud-icon">
                                    <img src="assets/images/CloudMaster-3.png" alt="Only with Cloud Master!"/>
                                </div>
                                <div className="cloud-content">
                                    <span className="master_name_3">Only with Cloud Master!</span>
                                    <p className="master_3">You can easily set up your account and link your cctv using
                                        our system
                                        for streaming.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="magic">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-6 parent_ul no_ul">
                            <div className="big_hdr blue_hdr hdr_26 bold_hdr magic_name_1">No Gimmicks</div>
                            <div className="big_hdr blue_hdr hdr_26 light_hdr magic_name_2">We handle everything in a
                                user-friendly and comprehensible manner
                            </div>
                            <ul>
                                <li className="magic_1">Curious about why thousands of people choose Ecams Cloud Storage
                                    and why
                                    we outshine our competitors? We don't resort to deceit or complexity.
                                </li>
                                <li className="magic_2">Our technology is the most user-friendly, and our prices are
                                    affordable
                                    for everyone. This is why our all-inclusive offering, Ecams Cloud Storage, remains
                                    the
                                    leader in terms of the price-to-quality ratio in the cloud video surveillance
                                    market.
                                </li>
                            </ul>
                            <div className="parent_btn blue_btn big_btn h_btn_60 right_btn">
                                <div className="child_btn partnership_btn">
                                    <a href="/advantages"><span>Learn More</span><i
                                        className="fa fa-angle-right fa-3x"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wview">
                <div className="container">
                    <div className="row">
                        <div className="col12 col-lg-6 parent_ul no_ul">
                            <div className="big_hdr blue_hdr hdr_26 bold_hdr wview_name_1">Directly Record Video from
                                Your Ecams
                                Cameras into the Cloud
                            </div>
                            <div className="big_hdr blue_hdr hdr_26 light_hdr wview_name_2">No Matter Where You Are
                            </div>
                            <ul>
                                <li className="wview_1">You, the camera, and Ecams Cloud Storage. These are the only
                                    essentials
                                    for recording.
                                </li>
                                <li className="wview_2">Choose how you want to record video, broadcast it, or utilize
                                    any other
                                    features that the service provides.
                                </li>
                            </ul>
                            <div className="parent_btn blue_btn big_btn h_btn_60 right_btn">
                                <div className="child_btn partnership_btn">
                                    <a href="howitworks"><span>Learn More</span><i
                                        className="fa fa-angle-right fa-3x"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="showcase">
                <div className="showcase-wrap-anydevice">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-lg-6">
                                <img src="assets/images/devices-bg.png" alt=""/>
                            </div>

                            <div className="col-12 col-lg-6 anydevice_t">
                                <div className="big_hdr blue_hdr hdr_26 bold_hdr motion_name_1">Life in Motion</div>
                                <div className="blue_hdr hdr_26 light_hdr motion_name_2">Time never stops, and neither
                                    do our
                                    recordings!
                                </div>
                                <ul>
                                    <li className="motion_1">Ecams Cloud Storage ensures you don't miss any important
                                        events. Our
                                        recordings run continuously, 24 hours a day, with motion detection moments
                                        marked for
                                        easy access to archived videos.
                                    </li>
                                    <li className="ul_16 motion_2">Make use of our mobile applications:</li>
                                </ul>
                                <div className="">
                            <span className="mobile_store mr">
                                <a href="" target="_blank">
                                    <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                </a>
                            </span>
                                    <span className="mobile_store">
                                    <button disabled>
                                        <img src="assets/images/Google_Play_Store_badge_EN.svg"
                                             alt="Get it on Google Play"/>
                                   </button>
                            </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="simplestart">
                <div className="showcase-wrap-simplestart">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-block d-lg-none">
                                    <div className="big_hdr white_hdr hdr_26 bold_hdr simplestart_name">The Easiest
                                        Cloud
                                        Connection You Can Imagine!
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <img src="assets/images/guide_mob-1.png" className="center-block guide"/>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <p className="simplestart_1">Install the camera</p>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <img src="assets/images/guide_mob-2.png" className="center-block guide"/>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <p className="simplestart_2">Connect the camera to the power supply
                                                and router</p></div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <img src="assets/images/guide_mob-3.png"
                                                 className="center-block guide"/>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12"><p className="simplestart_3">Use Cloud Master to add
                                            the camera to
                                            your account!</p></div>
                                    </div>
                                </div>

                                <div className="d-none d-lg-block">
                                    <div className="row justify-content-center g-0">
                                        <div className="col-12 col-lg-10">
                                            <img src="assets/images/guide.png" className="mb-4"/>

                                                <div className="row">
                                                    <div className="col-12 col-lg-4 justify-content-start">
                                                        <p className="simplestart_1 pe-5">
                                                            Install the camera</p>
                                                    </div>
                                                    <div className="col-md-4"><p className="simplestart_2">Connect the
                                                        camera to the power
                                                        supply
                                                        and router
                                                    </p></div>
                                                    <div className="col-md-4"><p className="simplestart_3">Use Cloud
                                                        Master to add the
                                                        camera to
                                                        your account!</p></div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
)
}

export default HomePage;