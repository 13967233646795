import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from 'yup';
import {ref, string} from 'yup';
import emailjs from 'emailjs-com';
import {useState} from "react";

const RegistrationPage = () => {
    const [success, setSuccess] = useState(false);

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Field is required'),
        password: Yup.string().required('Field is required'),
        confirmPassword: string()
            .required("Please re-type your password")
            .oneOf([ref("password")], "Passwords does not match"),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        localStorage.setItem("userEmail", values?.email);
        localStorage.setItem("password", values?.password);

        localStorage.removeItem("device_name");
        localStorage.removeItem("ip_address");
        localStorage.removeItem("port");

        emailjs
            .send(
                'service_abz83dd',
                'template_5mu6ryq',
                values,
                'LaTWr3rDMXIhQ6e3L'
            )
            .then(
                () => {
                    setSuccess(true)
                    // localStorage.setItem("userEmail", values?.email);
                    // window.location.href = '/';
                },
                (error) => {
                    console.log(error)
                }
            )
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <MetaTags>
                <title>Login</title>
            </MetaTags>

            <div className="wview">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-12 col-lg-6">
                            <div className="card w-100">
                                <div className="p-4">
                                    {!success && <Formik
                                        initialValues={{email: '', password: '', confirmPassword: ''}}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <h2 className="mb-3 fw-bold">
                                                    Registration
                                                </h2>
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        label="Email"
                                                        error={errors.email && touched.email}
                                                        helperText={errors.email && touched.email && errors.email}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        label="Password"
                                                        error={errors.password && touched.password}
                                                        helperText={errors.password && touched.password && errors.password}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        type="password"
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        label="Confirm password"
                                                        error={errors.confirmPassword && touched.confirmPassword}
                                                        helperText={errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                                                    />
                                                </div>

                                                <button
                                                    className="parent_btn blue_btn big_btn h_btn_60 right_btn w-100"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "..." : "Registration"}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>}

                                    {success && <p className="py-5 text-center">
                                        We will review your request and get back to you shortly
                                    </p>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationPage;