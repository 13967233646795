const NavbarComponent = () => {
    const email = localStorage.getItem("userEmail");

    return (
        <header>
            <section className="hero">
                <div className="container">
                    <div className="row d-flex align-items-center mb-5">
                        <div className="col-6 col-lg-6 text-left">
                            <a href="/" className="brand">
                                <img src="assets/images/logo.webp" className="logo w-100 w-lg-auto"/>
                            </a>
                        </div>
                        <div className="col-6 col-lg-6 d-flex justify-content-end align-items-center">
                            {email && (
                                <>
                                    <a className="bbtn btn-login" href="/profile">Profile</a>
                                    <a className="bbtn btn-login" href="#"
                                       onClick={() => {
                                           localStorage.removeItem("userEmail");
                                           window.location.href = '/';
                                       }}
                                    >Logout</a>
                                </>
                            )}

                            {!email && <a className="bbtn btn-login" href="/login">Login</a>}
                            {!email && <a className="bbtn bbtn-reg" href="/registration">Registration</a>}
                            <div className="dropdown">
                                <a className="text-uppercase"
                                   href="#"
                                   role="button" id="dropdownMenuLink"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    <span>Menu</span>
                                    <span><i className="fa-solid fa-bars"></i></span>
                                </a>

                                <ul className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink">
                                    <li>
                                        <a className="dropdown-item" href="/">
                                            <span className="me-2 icon"><i className="fa-solid fa-house"></i></span>
                                            <span>Start page</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/contacts">
                                        <span className="me-2 icon">
                                            <i className="fa-solid fa-address-book"></i>
                                        </span>
                                            <span>Contacts</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div className="row hero-content align-items-end">
                        <div className="col-md-7 col-xs-12">
                            <h1 className="main_intro mb-5">Cloud video surveillance</h1>

                            <div className="mb-5">
                                <div className="mobile_store mr">
                                    <a href="/" target="_blank">
                                        <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                    </a>
                                </div>
                                <div className="mobile_store">
                                    <button disabled>
                                        <img src="assets/images/Google_Play_Store_badge_EN.svg"
                                             alt="Get it on Google Play"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 hidden-sm hidden-xs header_img">
                            <img src="assets/images/header_cameras.png" alt="" className="header_cameras"/>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    );
}

export default NavbarComponent;